import React from "react";
import "./Laptop.css";

function Laptop() {
  return (
    <>
      <div class="container">
        <p className="laptext"  data-aos="flip-left">
          Hover Here!
          <br />
          <i className="fas fa-angle-double-down"></i>
        </p>
        <div class="mockup mockup-macbook loaded opened" data-aos="fade-left">
          <div class="part top">
            <img
              src="https://d1xm195wioio0k.cloudfront.net/images/mockup/macbook-top.svg"
              alt=""
              class="top"
            />
            <img
              src="https://d1xm195wioio0k.cloudfront.net/images/mockup/macbook-cover.svg"
              alt=""
              class="cover"
            />

            <video autoPlay controls loop>
              <source
                src="/assest/Welcome.mp4"
                type="video/mp4"
              />
            </video>
          </div>

          <div class="part bottom">
            <img
              src="https://d1xm195wioio0k.cloudfront.net/images/mockup/macbook-cover.svg"
              alt=""
              class="cover"
            />
            <img
              src="https://d1xm195wioio0k.cloudfront.net/images/mockup/macbook-bottom.svg"
              alt=""
              class="bottom"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Laptop;
