import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "./SecFive.css";
import "swiper/css";
import "swiper/css/autoplay";
import { EffectFade } from "swiper/modules";

import "swiper/css";

import { Autoplay } from "swiper/modules";
function SecFive() {
  const images = [
    "/assest/images/Spectilies/Icons/Administrative.png",
    "/assest/images/Spectilies/Icons/Admiralty (Maritime).png",
    "/assest/images/Spectilies/Icons/Alternative Dispute Resolution (ADR).png",
    "/assest/images/Spectilies/Icons/Antitrust.png",
    "/assest/images/Spectilies/Icons/Bankruptcy.png",
    "/assest/images/Spectilies/Icons/Civil Litigation.png",
    "/assest/images/Spectilies/Icons/Civil Rights.png",
    "/assest/images/Spectilies/Icons/Constitutional.png",
    "/assest/images/Spectilies/Icons/Construction.png",
    "/assest/images/Spectilies/Icons/Consumer.png",
    "/assest/images/Spectilies/Icons/Corporate.png",
    "/assest/images/Spectilies/Icons/Criminal.png",
    "/assest/images/Spectilies/Icons/Cyber.png",
    "/assest/images/Spectilies/Icons/Education.png",
    "/assest/images/Spectilies/Icons/Employment.png",
    "/assest/images/Spectilies/Icons/Environmental.png",
    "/assest/images/Spectilies/Icons/Estate Planning and Probate.png",
    "/assest/images/Spectilies/Icons/Family.png",
    "/assest/images/Spectilies/Icons/Health.png",
    "/assest/images/Spectilies/Icons/Immigration.png",
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  const getStyle = (slideIndex, centeredIndex) => {
    const totalImages = images.length;
    const adjustedCenteredIndex = (centeredIndex + totalImages) % totalImages;

    if (slideIndex === centeredIndex) {
      return {
        marginTop: "10%",
        position: "relative",

        transition: "margin-top 0.4s    ease-in-out, top 0.3s ease-in-out", // Add transition for marginTop and top properties
      };
    } else if (
      slideIndex === (adjustedCenteredIndex + totalImages - 1) % totalImages ||
      slideIndex === (adjustedCenteredIndex + 1) % totalImages
    ) {
      return {
        marginTop: "6%",
        position: "relative",

        transition: "margin-top 0.4s ease-in-out, top 0.3s ease-in-out", // Add transition for marginTop and top properties
      };
    } else {
      return {};
    }
  };

  return (
    <>
      <div style={{
          backgroundColor: "white",
          borderBottom:"solid"
        }}>
        <br />
        <br />

        <h2
          style={{ textAlign: "center", color: "black", fontWeight: "bold", fontFamily:"sans-serif" }}
          data-aos="zoom-in-up"
          className="spefontmobi"
        >
        OUR LEGAL EXPERTISE
        </h2>
        <br />
        <div className="container">
        <h4 className="sec5mobi" style={{ textAlign: "center", fontFamily:"sans-serif", color:"black" }}>
        Experience Seamless, Specialized, 
          <span style={{ color: "black", fontFamily:"sans-serif" }}>24/7</span> Online Legal Consultations from anywhere in the world. Unleash the Power of Convenient, Personalized Legal Assistance Today.
        </h4>
        </div>
        {/* Swiper */}
        <div className="container">
          <Swiper
            spaceBetween={10}
            slidesPerView={5}
            modules={[Autoplay, EffectFade]}
            onSlideChange={(swiper) => setActiveIndex(swiper.realIndex + 2)}
            autoplay={true}
            loop={true}
            speed={2000}
            style={{textAlign:"center"}}
            // Add the slide effect for transition animation

            onSwiper={(swiper) => console.log(swiper)}
          >
            {images.map((res, index) => (
              <SwiperSlide
                key={index}
                className="swiper-slide" // Apply the custom animation class
                style={getStyle(index, activeIndex % images.length)}
              >
                <img alt="" key={index} src={res} className="img-fluid" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div style={{ textAlign: "center" }}>
          <Link
            to="/Specialities"
            className="btnviewhover"
            style={{
              paddingTop: "4px",
              color: "white",
              backgroundColor: "black",
              boxShadow: " -1px 1px 10px 2px gray",
              padding: "10px 40px",
              borderRadius: "18px",
            }}
          >
            <b style={{ fontFamily: "sans-serif" }}>View All Specialities</b>
          </Link>
        </div>
        <br />
        <br />
      </div>
    </>
  );
}

export default SecFive;
