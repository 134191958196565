import React from "react";
import ContactHeader from "../Contact/ContactHeader";
import Footer from "../Footer/Footer";
import "../Features/Features.css";
function Features() {
  return (
    <>
      <ContactHeader />
      <div
        id="contact-area"
        className="contact-area"
        style={{
          backgroundImage: "url('/assest/images/HomeImages/SecEight.png')",
          backgroundColor: "#ffffffcc",
          backgroundBlendMode: "overlay",
          backgroundSize: "cover",
          backgroundRepeat:"no-repeat",
        }}
      >
        <div className="container pb-5 pt-1">
          <section id="advertisers" class="advertisers-service-sec pt-5 pb-5">
            <div class="container">
              <div class="row">
                <div class="section-header text-center">
                  <h2
                    class="fw-bold fs-1"
                    data-aos="flip-left"
                    style={{ color: "black" , fontFamily:"sans-serif"}}
                  >
                    FEATURES
               
                  </h2>
                
                </div>
              </div>
              <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
                <div class="col">
                  <div class="service-card">
                    
                    <h3 style={{fontFamily:"sans-serif"}}>
                    Revolutionizing Legal Accessibility With Our Comprehensive App
                    </h3>
                    <p style={{fontFamily:"sans-serif"}}>
                    In the ever-evolving landscape of legal services, our app emerges as a comprehensive solution, combining cutting-edge technology with practical solutions to redefine your legal experience. We aim to bridge the gap between clients and legal professionals by revolutionizing the way legal services are delivered.
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="service-card">
                   
                    <h3 style={{fontFamily:"sans-serif"}}>On-Demand AI Chatbot For Consistent Legal Guidance</h3>
                    <p style={{fontFamily:"sans-serif"}}>
                    Our legal services app hosts an intuitive AI chatbot, always at your service. This digital assistant is programmed to guide you through any legal-related confusions, answering your questions and providing information whenever you need it. With this feature, reliable legal advice is just a tap away.
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="service-card" style={{backgroundColor:"black"}}>
                 
                    <h3 style={{fontFamily:"sans-serif"}}>
                    Experience The Convenience Of Online Consultations And E-Legal Documents
                    </h3>
                    <p style={{fontFamily:"sans-serif"}}>
                    The era of standing in queues and rushing to physical offices is over. Our app's key feature includes the convenience of scheduling online consultations. You can connect with your preferred legal professionals at your own convenience.
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="service-card">
                    
                    <h3 style={{fontFamily:"sans-serif"}}>Safe And Secure E-Payments</h3>
                    <p style={{fontFamily:"sans-serif"}}>
                    Our legal services app places high priority on the security of your transactions. We support secure e-payments, ensuring a seamless and cashless experience. Our robust encryption practices keep your transactions confidential and secure, providing you peace of mind every time you use our services.
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="service-card">
                   
                    <h3 style={{fontFamily:"sans-serif"}}>Say Goodbye To Wasted Time And Money</h3>
                    <p style={{fontFamily:"sans-serif"}}>
                    Long waiting times and stressful journeys to legal offices can now be a thing of the past. Our app's primary goal is to save your time and money by enabling you to manage your legal needs from the comfort of your home. No more waiting, no more traffic, just convenient and stress-free legal services at your fingertips.
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="service-card">
                    
                    <h3 style={{fontFamily:"sans-serif"}}>Discover Your Preferred Legal Professionals With Ease</h3>
                    <p style={{fontFamily:"sans-serif"}}>
                    Looking for a specific type of lawyer in your area? Need to locate the nearest legal office quickly? Our intelligent search feature and integrated in-app map make it easy to find lawyers, law firms, and legal professionals based on specialty, location, or any other preference you might have. Legal services have never been this personalized.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                
              </div>
            </div>
          </section>
          {/* <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-2"></div>
            <div className="col-md-2" data-aos="zoom-in-right">
              <img src="/assest/images/Feuters/1.png" alt="contact1" />
            </div>
            <div className="col-md-2" data-aos="zoom-in-up">
              <img src="/assest/images/Feuters/1.png" alt="contact2" />
            </div>
            <div className="col-md-2" data-aos="zoom-in-left">
              <img src="/assest/images/Feuters/1.png" alt="contact3" />
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-1"></div>
          </div> */}
          {/* <br />
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-2"></div>
            <div className="col-md-2" data-aos="zoom-in-right">
              <img src="/assest/images/Feuters/1.png" alt="contact1" />
            </div>
            <div className="col-md-2" data-aos="zoom-in-down">
              <img src="/assest/images/Feuters/1.png" alt="contact2" />
            </div>
            <div className="col-md-2" data-aos="zoom-in-left">
              <img src="/assest/images/Feuters/1.png" alt="contact3" />
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-1"></div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Features;
