import React from "react";

const About = () => {
  return (
    <>
      {/* <!-- Feature Area============================================ --> */}
      <div
        id="feature-area"
        className="feature-area  pt-30 pb-30"
        style={{
          backgroundImage:
            "url(/assest/images/HomeImages/time-healing-concept-top-view.png)",
          backgroundSize:"cover",
          // backgroundRepeat:"no-repeat"
          // background:"black",
          // borderBottom:"solid"
        }}
      >
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="row">
            <div className="col-md-6">
              <div
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <h1
                  className="mobimedical"
                  style={{
                    fontSize: "71px",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                    color: "black",
                  }}
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  Legal Professionals Ready for Online Consultations
                </h1>
                <h1
                  className="mobimedicalnum"
                  style={{
                    fontSize: "71px",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                    color: "black",
                  }}
                  data-aos="fade-left"
                >
                  24/7
                </h1>
                <p
                  style={{
                    fontSize: "20px",
                    color: "black",
                    fontFamily: "sans-serif",
                  }}
                  data-aos="flip-right"
                >
                 At Lawfully, we uphold the belief that legal services should be as unique as you are. Our state-of-the-art AI technology meticulously examines your legal history, requirements, and individual preferences to develop a customized legal strategy tailored to your specific needs. Say goodbye to generic solutions. With Lawfully, you have command over your legal matters. Access the right legal assistance when and where you need it.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mainicon mobimainicon" data-aos="flip-left">
                <img src="/assest/images/HomeImages/Layer10.png" alt="about1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
