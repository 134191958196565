import React from "react";
import "./Privacypolicy.css";
import { Link } from "react-router-dom";

function Privacypolicy() {
  return (
    <div style={{ backgroundColor: "black" }}>
      <div className="text-center pb-1 pt-3">
        <Link to="/">
          <img src="/assest/images/HomeImages/logo.png" alt="Privacypolicy" />
        </Link>
      </div>
      <div className="content">
        <div className="Practitionersbox">
          <h2 className="text-white text-center">
            Lawfully Professional Terms and Conditions for Legal Offices
          </h2>
          <h3 className="pt-3 pb-3">Terms & Conditions</h3>
          <hr />
          <h1 className="pt-3 pph1">Privacy Policy</h1>

          <ol>
            <li>
              <strong>Introduction:</strong>Welcome to Lawfully, an advanced
              platform fostering collaboration between legal offices, lawyers,
              and clients. By engaging with our services, legal offices are
              expected to acknowledge and adhere to the following terms and
              conditions.
            </li>

            <li>
              <strong>Legal Office Registration and Subscription:</strong>2.1
              Registration Process: Legal offices must meticulously complete the
              registration process, providing accurate details about their team
              and legal services.
              <br />
              2.2 Subscription Plans: Lawfully provides a range of subscription
              plans based on the number of practitioners in a legal office.
              Legal offices are responsible for selecting the appropriate plan
              during the registration process.
            </li>

            <li>
              <strong>Data Security and Compliance:</strong>3.1 Data Security
              Standards: Legal offices agree to uphold the highest standards of
              data security for the information shared and processed through the
              Lawfully platform.
            </li>
            <li>
              <strong>Account Creation and Practitioner Information:</strong>4.1
              Account Credentials: Upon subscription confirmation, Lawfully will
              create accounts for each listed practitioner, and login
              credentials will be provided to the legal office for distribution.
              <br />
              4.2 Practitioner Information: Legal offices are responsible for
              providing accurate and up-to-date information about each
              practitioner, including their name, phone number, email, and
              specialty.
            </li>
            <li>
              <strong>Appointment Management and Legal Services:</strong>5.1
              Appointment Scheduling: Legal offices can efficiently manage
              appointments for clients through the Lawfully platform, ensuring a
              professional and streamlined experience.
              <br />
              5.2 Client Interaction: The platform facilitates secure video
              consultations, enhancing accessibility to legal services and
              fostering a professional interaction between clients and lawyers.
            </li>
            <li>
              <strong>Payments and Transactions</strong>6.1 Stripe Integration:
              All financial transactions, including subscription fees and
              practitioner payments, are processed securely through the Stripe
              payment gateway integrated into the Lawfully platform.
            </li>
            <li>
              <strong>Termination of Services</strong>7.1 Termination by
              Lawfully: Lawfully retains the right to terminate services to a
              legal office in case of non-compliance with these terms or any
              illegal or unethical activities.
            </li>
            <li>
              <strong>Governing Law</strong>8.1 Jurisdiction: These terms and
              conditions are governed by and construed in accordance with the
              laws.
            </li>
            <li>
              <strong>Changes to Terms</strong>9.1 Modification: Lawfully
              reserves the right to modify these terms and conditions at any
              time. Legal offices will be notified of significant changes, and
              continued use of the platform constitutes acceptance of the
              modified terms.
              <br />
              By registering your legal office with Lawfully, you agree to abide
              by these terms and conditions. For any questions or concerns,
              please contact our dedicated support team.
            </li>
          </ol>
          {/* ------------ */}
        </div>
      </div>
      <hr />
      <div className="content">
        <div className="Practitionersbox">
          <h2 className="text-white text-center">
            Lawfully Professional Terms and Conditions for Clients
          </h2>
          <h3 className="pt-3 pb-3">Terms & Conditions</h3>
          <hr />
          <h1 className="pt-3 pph1">Privacy Policy</h1>

          <ol>
            <li>
              <strong>Introduction:</strong>Welcome to Lawfully, an esteemed
              platform facilitating seamless collaboration between clients,
              legal practitioners, and legal offices. By availing our services,
              you are expected to acknowledge and adhere to the following terms
              and conditions.
            </li>

            <li>
              <strong>Client Registration and Interaction:</strong>2.1
              Registration Process: Clients are urged to complete the
              registration process diligently, providing accurate and pertinent
              information for optimal engagement with legal services.
            </li>

            <li>
              <strong>Appointment Booking and Legal Services:</strong>We use
              information that we collect about you or that you provide to us,
              including any personal information, to provide you with
              information, products, or services that you request from us, to
              fulfill any other purpose for which you provide it, to carry out
              our obligations and enforce our rights arising from any contracts
              entered into between you and us, and in any other way we may
              describe when you provide the information.
            </li>
            <li>
              <strong>Disclosure of Your Information:</strong>3.1 Appointment
              Management: Clients benefit from the efficient management of
              appointments through the Lawfully platform, ensuring a convenient
              and professional experience.
              <br />
              3.2 Legal Consultations: The platform offers secure video
              consultations, enhancing accessibility to legal services and
              fostering a professional interaction between clients and lawyers.
            </li>
            <li>
              <strong>Payments and Transactions:</strong>You have certain rights
              under applicable data protection laws, including the right to
              access and update your personal information, restrict how it is
              used, transfer certain personal information to another controller,
              withdraw your consent at any time, and the right to have us erase
              certain personal information about you.
            </li>
            <li>
              <strong>Changes to Our Privacy Policy:</strong>4.1 Payment
              Process: Clients engage in secure financial transactions,
              including payment for appointment fees, facilitated seamlessly
              through Lawfully's advanced payment system.
            </li>
            <li>
              <strong>Confidentiality and Data Security</strong>5.1 Client
              Confidentiality: Clients can trust in the utmost confidentiality
              of their information, with Lawfully committed to maintaining the
              highest standards of privacy and data security.
            </li>
            <li>
              <strong>Termination of Services</strong>6.1 Termination by
              Lawfully: Lawfully retains the authority to terminate services to
              clients in the event of non-compliance with these terms or any
              unlawful or unethical activities.
            </li>
            <li>
              <strong>Governing Law</strong>7.1 Jurisdiction: These meticulously
              drafted terms and conditions are governed by the laws, providing
              the legal framework for client engagement on the Lawfully
              platform.
            </li>
            <li>
              <strong>Changes to Terms</strong>8.1 Modification: Lawfully
              reserves the right to judiciously modify these terms and
              conditions. Clients will be duly informed of significant
              alterations, and continued use of the platform signifies
              unequivocal acceptance of the revised terms.
              <br />
              By choosing to register as a client on the Lawfully platform, you
              acknowledge your commitment to full compliance with these terms
              and conditions. For inquiries or assistance, our dedicated support
              team is at your disposal.
            </li>
          </ol>
        </div>
      </div>
      {/* - */}
      <div className="content">
        <div className="Practitionersbox">
          <h2 className="text-white text-center">
            Lawfully Professional Terms and Conditions for Lawyers
          </h2>
          <h3 className="pt-3 pb-3">Terms & Conditions</h3>
          <hr />
          <h1 className="pt-3 pph1">Privacy Policy</h1>

          <ol>
            <li>
              <strong>Introduction:</strong>Welcome to Lawfully, a sophisticated
              platform uniting legal practitioners, clients, and legal offices
              to streamline legal services. By availing our services, you
              acknowledge and commit to the adherence of the ensuing terms and
              conditions.
            </li>

            <li>
              <strong>Lawyer Registration and Subscription:</strong>2.1
              Registration Process: Lawyers are required to meticulously
              complete the registration process, furnishing accurate details
              regarding their professional credentials and legal practice.
              <br />
              2.2 Subscription Plans: Lawfully extends a spectrum of
              subscription plans tailored to individual legal practitioners.
              Lawyers are entrusted with the responsibility of selecting a plan
              befitting their practice during the registration process.
            </li>

            <li>
              <strong>Data Security and Compliance:</strong>3.1 Legal and
              Ethical Standards: Lawyers are held accountable to conduct their
              professional affairs in strict accordance with the prevailing
              legal and ethical standards of their jurisdiction.
              <br />
              3.2 Client Confidentiality: Lawyers explicitly recognize and
              commit to upholding the sanctity of client confidentiality,
              adhering rigorously to the highest benchmarks of privacy and data
              security.
            </li>
            <li>
              <strong>Client Interaction and Legal Services:</strong>4.1
              Appointment Management: Lawyers wield the capability to adeptly
              manage appointments through the Lawfully platform, ensuring a
              seamless and sophisticated experience for clients.
              <br />
              4.2 Legal Consultations: The platform seamlessly facilitates
              secure video consultations, elevating accessibility to legal
              services and fostering a professional client-lawyer interaction.
            </li>
            <li>
              <strong>Payments and Transactions:</strong>5.1 Payment Processing:
              All financial transactions, encompassing appointment fees and
              subscription payments, are impeccably and securely processed
              through Lawfully's integrated and cutting-edge payment system.
            </li>
            <li>
              <strong>Termination of Services:</strong>6.1 Termination by
              Lawfully: Lawfully retains the prerogative to terminate services
              to any lawyer found in contravention of these terms or engaging in
              any unlawful or unethical activities.
            </li>
            <li>
              <strong>Governing Law:</strong>7.1 Jurisdiction: These
              meticulously drafted terms and conditions find resonance and
              interpretation under the laws, governing the professional
              engagement of lawyers on the Lawfully platform.
            </li>
            <li>
              <strong>Changes to Terms:</strong>8.1 Modification: Lawfully
              reserves the right to judiciously modify these terms and
              conditions, with lawyers receiving due notification of substantial
              alterations. Continued use of the platform unequivocally signifies
              acceptance of the revised terms.
              <br />
              By electing to register as a lawyer on the Lawfully platform, you
              assert your commitment to unwavering adherence to these terms and
              conditions. For any queries or concerns, we encourage you to
              engage with our dedicated support team.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default Privacypolicy;
