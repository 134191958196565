import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import "./SecFive.css";
import "swiper/css";
import "swiper/css/autoplay";
import { EffectFade } from "swiper/modules";

import "swiper/css";
import './Specialities.css'

import { Autoplay } from "swiper/modules";

import ContactHeader from "../Contact/ContactHeader";
import Footer from "../Footer/Footer";

function Specialities() {
  const images = [
    "/assest/images/Spectilies/Icons/Administrative.png",
    "/assest/images/Spectilies/Icons/Admiralty (Maritime).png",
    "/assest/images/Spectilies/Icons/Alternative Dispute Resolution (ADR).png",
    "/assest/images/Spectilies/Icons/Antitrust.png",
    "/assest/images/Spectilies/Icons/Bankruptcy.png",
    "/assest/images/Spectilies/Icons/Civil Litigation.png",
    "/assest/images/Spectilies/Icons/Civil Rights.png",
    "/assest/images/Spectilies/Icons/Constitutional.png",
    "/assest/images/Spectilies/Icons/Construction.png",
    "/assest/images/Spectilies/Icons/Consumer.png",
    "/assest/images/Spectilies/Icons/Corporate.png",
    "/assest/images/Spectilies/Icons/Criminal.png",
    "/assest/images/Spectilies/Icons/Cyber.png",
    "/assest/images/Spectilies/Icons/Education.png",
    "/assest/images/Spectilies/Icons/Employment.png",
    "/assest/images/Spectilies/Icons/Environmental.png",
    "/assest/images/Spectilies/Icons/Estate Planning and Probate.png",
    "/assest/images/Spectilies/Icons/Family.png",
    "/assest/images/Spectilies/Icons/Health.png",
    "/assest/images/Spectilies/Icons/Immigration.png",
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  const getStyle = (slideIndex, centeredIndex) => {
    const totalImages = images.length;
    const adjustedCenteredIndex = (centeredIndex + totalImages) % totalImages;

    if (slideIndex === centeredIndex) {
      return {
        marginTop: "10%",
        position: "relative",

        transition: "margin-top 0.4s    ease-in-out, top 0.3s ease-in-out", // Add transition for marginTop and top properties
      };
    } else if (
      slideIndex === (adjustedCenteredIndex + totalImages - 1) % totalImages ||
      slideIndex === (adjustedCenteredIndex + 1) % totalImages
    ) {
      return {
        marginTop: "6%",
        position: "relative",

        transition: "margin-top 0.4s ease-in-out, top 0.3s ease-in-out", // Add transition for marginTop and top properties
      };
    } else {
      return {};
    }
  };
  return (
    <>
      <ContactHeader />
      <div
        id="contact-area"
        className="contact-area"
        style={{
          backgroundImage: "url('/assest/images/HomeImages/SecEight.png')",
          backgroundColor: "#ffffffcc",
          overflow: "revert-layer",
          backgroundSize: "cover",
          backgroundBlendMode: "overlay",
        }}
      >
        <div className="container pb-30 pt-30">
          {/* <!-- Section Title --> */}
          <div className="text-center col-12">
            <h3
              className="heading"
              style={{
                color: "black",
                marginLeft: "18px",
                fontWeight: "bolder",
                fontFamily: "sans-serif",
              }}
              data-aos="flip-left"
            >
              OUR EXPERTISE
            </h3>
          </div>

          <div className="container">
            <Swiper
              spaceBetween={10}
              slidesPerView={5}
              modules={[Autoplay, EffectFade]}
              onSlideChange={(swiper) => setActiveIndex(swiper.realIndex + 2)}
              autoplay={true}
              loop={true}
              speed={2000}
              style={{ textAlign: "center" }}
              // Add the slide effect for transition animation

              onSwiper={(swiper) => console.log(swiper)}
            >
              {images.map((res, index) => (
                <SwiperSlide
                  key={index}
                  className="swiper-slide" // Apply the custom animation class
                  style={getStyle(index, activeIndex % images.length)}
                >
                  <img alt="" key={index} src={res} className="img-fluid" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* <SecFive /> */}
        </div>
      </div>
      {/* ------------ */}
      <div id="contact-area" className="contact-area">
        <div className="container pb-30 pt-30">
          {/* <!-- Section Title --> */}
          <div className="text-center col-12">
            <h3
              className="heading"
              style={{
                color: "black",
                fontWeight: "bolder",
                fontFamily: "sans-serif",
              }}
            >
              All Expertise
            </h3>
          </div>

          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Administrative.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Administrative</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Navigate administrative complexities seamlessly with our mobile
                app, empowering lawyers and clients in the realm of
                administrative law.
              </h6>
            </div>
          </div>
          {/* 2 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Admiralty (Maritime).png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Admiralty (Maritime)</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Sail through legal waters confidently – our mobile app is your
                compass in navigating the intricacies of Admiralty (Maritime)
                law.
              </h6>
            </div>
          </div>
          {/* 3 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Alternative Dispute Resolution (ADR).png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Alternative Dispute Resolution (ADR)</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Resolve conflicts efficiently with our mobile app designed to
                streamline Alternative Dispute Resolution (ADR) processes for
                lawyers and their clients.
              </h6>
            </div>
          </div>
          {/* 4 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Antitrust.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Antitrust</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Stay ahead in the competitive landscape with our mobile app,
                your ally in tackling Antitrust law challenges for lawyers and
                clients alike.
              </h6>
            </div>
          </div>
          {/* 5 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Bankruptcy.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Bankruptcy</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Empower financial comebacks with our mobile app, offering
                comprehensive support for lawyers and clients navigating the
                intricacies of Bankruptcy law.
              </h6>
            </div>
          </div>
          {/* 6 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Civil Litigation.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Civil Litigation</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Unleash the power of effective representation in Civil
                Litigation – our mobile app is your toolkit for success in the
                courtroom, connecting lawyers and clients seamlessly.
              </h6>
            </div>
          </div>
          {/* 7 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Civil Rights.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Civil Rights</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Champion justice and equality with our mobile app, empowering
                lawyers and clients to navigate the nuances of Civil Rights law
                effortlessly.
              </h6>
            </div>
          </div>
          {/* 8 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Constitutional.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Constitutional</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Unlock the principles that govern with our mobile app, your
                guide to Constitutional law, connecting lawyers and clients to
                the heart of legal rights and protections.
              </h6>
            </div>
          </div>
          {/* 9 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Construction.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Construction</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Build a solid foundation for legal success in the construction
                industry. Our mobile app is your tool for navigating
                complexities and ensuring compliance with Construction law.
              </h6>
            </div>
          </div>
          {/* 10 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Consumer.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Consumer</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Empower consumers and protect their rights with our mobile app
                designed to simplify the complexities of Consumer law for
                lawyers and their clients.
              </h6>
            </div>
          </div>

          {/* 11 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Corporate.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Corporate</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Navigate the corporate landscape with confidence using our
                mobile app. Tailored for lawyers and clients, it's your
                essential guide to mastering the intricacies of Corporate law.
              </h6>
            </div>
          </div>
          {/* 12 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Criminal.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Criminal</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Defend justice and protect rights with our mobile app, your
                essential tool for lawyers and clients navigating the challenges
                of Criminal law seamlessly.
              </h6>
            </div>
          </div>
          {/* 13 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Cyber.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Cyber</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Stay ahead in the digital realm with our mobile app. Tailored
                for lawyers and clients, it's your essential guide to navigating
                the complexities of Cyber law and protecting digital interests.
              </h6>
            </div>
          </div>
          {/* 14 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Education.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Education</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Elevate educational rights and compliance effortlessly with our
                mobile app, empowering lawyers and clients in the realm of
                Education law.
              </h6>
            </div>
          </div>
          {/* 15 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Employment.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Employment</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Empower workplaces with our mobile app, your essential guide to
                navigating the complexities of Employment law. Connecting
                lawyers and clients for seamless legal solutions.
              </h6>
            </div>
          </div>
          {/* 16 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Environmental.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Environmental</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Safeguard the planet with our mobile app – connecting lawyers
                and clients to navigate the intricate terrain of Environmental
                law, ensuring a sustainable and compliant future.
              </h6>
            </div>
          </div>
          {/* 17 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Estate Planning and Probate.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Estate Planning and Probate</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Secure legacies and navigate the probate process with ease. Our
                mobile app is your guide to mastering Estate Planning and
                Probate law for lawyers and their clients.
              </h6>
            </div>
          </div>
          {/* 18 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Family.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Family</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Guide families through legal matters with compassion and
                expertise. Our mobile app is tailored for lawyers and clients,
                simplifying the complexities of Family law with care and
                efficiency.
              </h6>
            </div>
          </div>
          {/* 19 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Health.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Health</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Ensure the well-being of all with our mobile app, your guide to
                navigating the intricate landscape of Health law. Connecting
                lawyers and clients for optimal legal health solutions.
              </h6>
            </div>
          </div>
          {/* 20 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Immigration.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Immigration</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Empower dreams and navigate borders seamlessly. Our mobile app
                is your essential tool for lawyers and clients navigating the
                complexities of Immigration law with expertise and efficiency.
              </h6>
            </div>
          </div>
          {/* 21 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Insurance.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Insurance</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Secure protection and navigate the complexities of Insurance law
                effortlessly. Our mobile app connects lawyers and clients,
                ensuring a smooth journey through the intricacies of insurance
                claims and regulations.
              </h6>
            </div>
          </div>
          {/* 22 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Intellectual Property.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Intellectual Property</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Safeguard innovation and creativity with our mobile app – the
                key to mastering Intellectual Property law. Connecting lawyers
                and clients for seamless protection of ideas and assets.
              </h6>
            </div>
          </div>
          {/* 23 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/International.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>International</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Navigate global legal landscapes with ease. Our mobile app is
                your passport to mastering International law, connecting lawyers
                and clients for seamless solutions across borders.
              </h6>
            </div>
          </div>
          {/* 24 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Mergers and Acquisitions (M&A).png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Mergers and Acquisitions (M&A)</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Elevate your business strategy with our mobile app, your guide
                to success in Mergers and Acquisitions (M&A) law. Connecting
                lawyers and clients for seamless transactions and growth.
              </h6>
            </div>
          </div>
          {/* 25 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Personal Injury.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Personal Injury</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Seek justice and support for personal injury cases with our
                mobile app. Tailored for lawyers and clients, it simplifies the
                complexities of Personal Injury law for optimal legal outcomes.
              </h6>
            </div>
          </div>
          {/* 26 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Real Estate.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Real Estate</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Unlock the door to successful property transactions with our
                mobile app. Tailored for lawyers and clients, it's your guide to
                navigating the complexities of Real Estate law with precision.
              </h6>
            </div>
          </div>
          {/* 27 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Securities.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Securities</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Invest with confidence and navigate financial markets
                seamlessly. Our mobile app connects lawyers and clients,
                ensuring expertise in the intricate world of Securities law for
                optimal financial outcomes.
              </h6>
            </div>
          </div>
          {/* 28 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Sports.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Sports</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Score legal victories in the world of sports with our mobile
                app. Tailored for lawyers and clients, it's your playbook for
                navigating the complexities of Sports law and ensuring fair play
                on and off the field.
              </h6>
            </div>
          </div>
          {/* 29 */}
          <br />
          <div
            className="row speicontextmobi"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-md-2">
              <img
                src="/assest/images/Spectilies/Icons/Tax.png"
                alt="contact1"
                className="speimgmobi"
              />
            </div>
            <div className="col-md-10 pt-2">
              <h5 style={{ fontFamily: "sans-serif" }}>
                <b>Tax</b>
              </h5>
              <h6 style={{ fontFamily: "sans-serif" }}>
                Optimize financial strategies with our mobile app, your
                essential guide to navigating the complexities of Tax law.
                Connecting lawyers and clients for seamless solutions in the
                fiscal landscape.
              </h6>
            </div>
          </div>
          {/* 30 */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Specialities;
