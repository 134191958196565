import React from "react";
import "../../AllSection/HomeSec/SecEight.css";
import { Link } from "react-router-dom";
import Laptop from "./Laptop";
// import Book from "./Book";
// import Robothandpic from "../../../assets/Images/HomeImages/sec3.png"

function SecEight() {
  return (
    <>
      {/* <!-- Hero Area============================================ --> */}
      <div
        className="fix fixmobi"
        style={{
          backgroundImage: "url('/assest/images/HomeImages/tec-law.png')",
          backgroundSize: "cover",
          // height: "500px",
        }}
      >
        <div className="container">
          <div className="hero-slide-content heromobi">
            <div className="row">
              <div className="col-md-5">
                <h2
                  style={{
                    color: "white",
                    fontSize: "48px",
                    fontFamily: "sans-serif",
                  }}
                  data-aos="zoom-in-right"
                >
                  Embark on the Future of
                  <br />
                  <h2
                    style={{
                      color: "black",
                      fontSize: "48px",
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                      textShadow: "0 0 10px #fff",
                    }}
                    className="digitaltextmobi"
                  >
                    Digital Legal Services
                  </h2>
                  Enhanced by AI
                </h2>
                <h5
                  style={{ color: "white", fontFamily: "sans-serif" }}
                  data-aos="flip-up"
                >
                  Schedule a consultation online
                  <br />
                  with the Legal Professional of your choice.
                  <br />
                  Accessible 24/7 on Lawfully
                  <br />
                  Legal Platform.
                </h5>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexDirection: "row-reverse",
                  }}
                >
                  <div className="googlemobipicdiv" data-aos="flip-up">
                    <Link
                      to={
                        "https://apps.apple.com/pk/app/virtual-triage/id1644720170"
                      }
                    >
                      <img
                        alt="baner1"
                        src="/assest/images/FooterImg/App.png"
                        style={{
                          border: "solid 1px white",
                          borderRadius: "25px",
                          scale: "0.7",
                          // marginLeft:"40px"
                        }}
                        //
                        className="eightapp mobiiconapp"
                      />
                    </Link>
                  </div>
                  <div className=" googlemobipicdiv" data-aos="flip-up">
                    <Link
                      to={
                        "https://play.google.com/store/apps/details?id=com.earcanada"
                      }
                    >
                      <img
                        alt="baner2"
                        src="/assest/images/FooterImg/google.png"
                        style={{
                          border: "solid 1px white",
                          borderRadius: "25px",
                          scale: "0.7",
                        }}
                        // data-aos="flip-left"
                        className="eightgoogle mobiiconapp"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                {/* <div  >
                  <img
                    src={Robothandpic}
                    alt="uk"
                    style={{ marginTop: "200px", marginLeft: "-121px", height:"55px" }}
                    data-aos="zoom-in"
                  />
                </div> */}
                {/* <Book /> */}
                
                <Laptop />
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecEight;
